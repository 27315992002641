.flag-icon-background {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.flag-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  line-height: 1em;
  position: relative;
  width: 1.33333333em;
}

.flag-icon::before {
  content: "\00a0";
}

.flag-icon.flag-icon-squared {
  width: 1em;
}

.flag-icon-gb {
  background-image: url(../flags/4x3/gb.svg);
}

.flag-icon-gb.flag-icon-squared {
  background-image: url(../flags/1x1/gb.svg);
}

.flag-icon-gr {
  background-image: url(../flags/4x3/gr.svg);
}

.flag-icon-gr.flag-icon-squared {
  background-image: url(../flags/1x1/gr.svg);
}

.flag-icon-es {
  background-image: url(../flags/4x3/es.svg);
}

.flag-icon-es.flag-icon-squared {
  background-image: url(../flags/1x1/es.svg);
}

.flag-icon-pl {
  background-image: url(../flags/4x3/pl.svg);
}

.flag-icon-pl.flag-icon-squared {
  background-image: url(../flags/1x1/pl.svg);
}
